.about-us__meet-the-team {
    margin-bottom: -100px;
    text-align: center;
    h2 {
        margin-bottom: 25px !important;
        @include font-size(22);
    }
    .about-us__person {
        margin-bottom: 50px;
        figure {
            height: 290px;
            background-size: cover;
            background-position: center top;
            border-bottom: 5px solid $mid_grey;
        }
        .about-us__person--content {
            h3 {
                margin: 20px 0 0;
                color: $mid_grey;
                @include font-size(16);
            }
            h4 {
                margin: 10px 0 20px;
                font-weight: 700;
                font-family: 'Karla', sans-serif;
                @include font-size(11);
            }
            p {
                line-height: 130%;
                a {
                    color: $text_grey;
                }
            }
        }
    }
    & > .row > div {
        margin-bottom: 35px;
        &:nth-of-type(3n+2) {
            animation-delay: .1s;
        }
        &:nth-of-type(3n+3) {
            animation-delay: .2s;
        }
    }
}