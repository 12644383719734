html, body {
    height: 100%;
}

body {
    padding-top: 140px;
    transition: all .2s ease;
    &.homepage {
        padding-top: 0;
        @media screen and (max-width: 768px) {
            padding-top: 140px;    
        }
    }
    &.customize-support header {
        top: 32px;
        @media screen and (max-width: 783px) {
            top: 46px;    
        }
    }
    @media screen and (max-width: 600px) {
        padding-top: 100px !important;    
    }
}

img {
    max-width: 100%;
}

.wrapper {
    max-width: 1250px;
    padding: 0 35px;
    margin: 0 auto;
    &.mid {
        max-width: 1000px;
    }
    &.narrow {
        max-width: 850px;
    }
}

.container {
    padding: 0;
    max-width: none !important;
    @media screen and (max-width: 767px) {
        padding: 0 15px;
    }
}

header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    width: 100%;
    padding: 40px 0;
    background: #FFF;
    transition: .3s ease;
    box-shadow: 0 0 8px #DDD;
    .wrapper {
        position: relative;
    }
    svg {
        width: 200px;
        transition: .3s ease;
        * {
            transition: fill .3s ease;
        }
        .block1 {
            fill: $light_blue;
        }
        .block2 {
            fill: $mid_blue;
        }
        .block3 {
            fill: $dark_blue;
        }
        .bwd *, .retail_property_consultants * {
            fill: $dark_blue;
        }
    }
    nav {
        display: none;
        ul {
            display: flex;
            justify-content: space-between;
            margin: 85px 0 50px !important;
            li {
                list-style: none;
                opacity: 0;
            }
            a {
                font-family: 'Cormorant Garamond', serif;
                font-weight: 600;
                @include font-size(18);
            }
        }
        @media screen and (max-width: 768px) {
            ul {
                display: block;
                margin: 25px 0 0 !important;
                li a {
                    display: block;
                    padding: 10px 0;
                    text-align: center;
                }
            }    
        }
    }
    &.open {        
        nav ul li {
            &:first-child {
                animation: fadeIn .5s forwards .25s;
            }
            &:nth-child(2) {
                animation: fadeIn .5s forwards .5s;
            }
            &:nth-child(3) {
                animation: fadeIn .5s forwards .75s;
            }
            &:nth-child(4) {
                animation: fadeIn .5s forwards 1s;
            }
            &:nth-child(5) {
                animation: fadeIn .5s forwards 1.25s;
            }
            &:nth-child(6) {
                animation: fadeIn .5s forwards 1.5s;
            }
        }
        & + .overlay {
            opacity: 1;
            display: block;
        }
    }
    .header__burger {
        width: 35px;
        height: 32px;
        position: absolute;
        top: 15px;
        right: 35px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: $dark_blue;
            opacity: 1;
            right: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;        
            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2), &:nth-child(3) {
                top: 14px;
                width: 65%;
            }
            &:nth-child(4) {
                top: 28px;
            }        
        }
        &.open {
            span {
                &:nth-child(1) {
                  top: 18px;
                  width: 0%;
                  left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                    width: 100%;
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                    width: 100%;
                }
                &:nth-child(4) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        height: 100px;
        padding: 20px 0;
        svg {
            width: 120px;
        }
    }
}

.overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1180;
    width: 100%;
    height: 100vh;
    background: rgba(255,255,255,.75);
    opacity: 0;
    transition: .3s ease;
}

@media screen and (min-width: 769px) {
    body.homepage  {
        header {
            background: transparent;
            box-shadow: none;
            .header__burger span {
                background: #FFF;
            }        
        }    
        &:not(.sticky) header:not(.open) svg * {            
            fill: #FFF;
        }
    }
}

body.sticky header,
header.open {
    padding: 25px 0;
    background: #FFF !important;
    box-shadow: 0 0 8px #DDD;
    svg {
        width: 170px;        
    }
    .header__burger {
        top: 15px; 
        span {
            background: $dark_blue !important;
        }
    }
    @media screen and (max-width: 600px) {
        height: auto;
        padding: 20px 0;
        svg {
            width: 120px;    
        }
    }
}

@media screen and (max-width: 600px) {
    header.open {
        height: 100vh !important;
    }
}

.panel {
    padding: 70px 0;
    .subtitle {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 700;
        color: $mid_grey;
        @media screen and (max-width: 600px) {
            @include font-size(9);
        }
    }
    h2 {
        margin-bottom: 50px;
        line-height: 120%;
        &:last-of-type {
            margin-bottom: 0;
        }
        @media screen and (max-width: 600px) {
            @include font-size(24);
            br {
                display: none;
            }
        }
        @media screen and (max-width: 400px) {
            @include font-size(18);
        }
    }
    .panel__cols {
        margin: 50px 0;
        column-count: 2;
        text-align: left;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @media screen and (max-width: 768px) {
            column-count: 1;
        }
        @media screen and (max-width: 400px) {
            margin: 25px 0;    
        }
    }
    .arrow-button {
        display: inline-block;
        margin: 20px 0 50px;
        text-transform: uppercase;
        font-weight: 700;
        color: $mid_grey;
        transition: .25s ease;
        &:hover {
            color: $light_blue;
        }
    }
    &.grey {
        background: $light_grey;
        .subtitle {
            color: $text-grey;
        }
    }
    &.alt-grey {
        background: $alt_grey;
    }
    &.mid-grey {
        background: $mid_grey;
    }
    &.centered {
        text-align: center;        
    }
    &.mid {
        padding: 40px 0;
    }
    &.narrow {
        padding: 15px 0;
    }    
    &.blank {
        min-height: 350px;
        @media screen and (max-width: 600px) {
            min-height: 120px;    
        }
    }
    .row:last-of-type .arrow-button {
        margin-bottom: 0;
    }
    @media screen and (max-width: 600px) {
        padding: 30px 0;
        text-align: left !important; 
        .wrapper {
            text-align: left !important; 
        }
    }
}

footer {
    padding: 35px 0 30px;
    border-top: 1px solid #EEE;
    .footer__logo {
        width: 160px;
    }
    .align-bottom {
        display: flex;
        p, ul {
            align-self: flex-end;
        }
    }
    p {
        @include font-size(10);
        a {
            color: $text_grey;
        }
        &.footer__help {
            max-width: 220px;
            margin-top: 25px;        
            font-family: 'Cormorant Garamond', serif;
            line-height: 120%;
            color: $dark_blue;
            @include font-size(16);
        }
    }
    ul {
        margin: 0;
        li {
            list-style: none;
            margin-bottom: 0;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        a {
            text-transform: uppercase;
            font-weight: 700;
            color: $mid_grey;
            @include font-size(9);
            img {
                display: none;
            }
        }
        @media screen and (max-width: 992px) {
            margin-top: 25px;
        }
    }
    .footer__mobile-links {
        display: none;
    }
    .footer__bottom {
        margin-top: 25px;
        padding: 25px 0 0;
        border-top: 1px solid #EEE;
        p {
            @include font-size(9);
        }
        .row > div:last-of-type p {
            text-align: right;
        }
    }
    
    @media screen and (max-width: 991px) {
        .footer__help, .footer__address, .footer__contact {
            padding-bottom: 25px;    
        }
        ul {
            width: 100%;
            margin: 0;
            li:nth-child(even) a {
                background: #FAFAF9;
            }
            a {
                position: relative;
                display: block;
                padding: 15px 35px;
                background: #F6F5F3;
                img {
                    position: absolute;
                    top: 21px;
                    right: 35px;
                    display: block;
                }
            }
            &.second li {
                a {
                    background: #FAFAF9;
                }
                &:nth-child(even) a {
                    background: #F6F5F3;
                }
            }
        }
        .footer__bottom {
            margin-top: 0;
            p {
                text-align: center !important;
            }
        }
    }
    
    @media screen and (max-width: 767px) {
        .wrapper {
            padding: 0;
        }
        .footer__help, .footer__address, .footer__contact {
            display: none;
        }
        .footer__top {
            display: flex;
            justify-content: space-between;
            .footer__logo {
                margin: 0 0 25px 35px;
            }
            .footer__mobile-links {
                display: inline-flex;
                justify-content: space-between;
                margin-right: 35px;
                width: 150px;
                img {
                    width: 40px;
                }
            }
        }
    }
    
    @media screen and (max-width: 400px) {
        .footer__logo {
            width: 110px;
        }
        .footer__top .footer__mobile-links img {
            width: 35px;
        }
            
    }
}

body.cookies-not-set footer {
    padding-bottom: 70px;
}

.results-grid {
    & > .row > div {
        margin-bottom: 35px;
        &:nth-of-type(3n+2) {
            animation-delay: .1s;
        }
        &:nth-of-type(3n+3) {
            animation-delay: .2s;
        }
    }
    a:hover .result {
        box-shadow: 0 0 25px #AAA;
        transform: scale(1.02);
    }
    .result {
        height: 100%;
        background: $light_grey;
        box-shadow: 0 0 10px #CCC;
        transition: .2s ease;
        .result--content {
            padding: 25px;                
            .result--details {
                margin-top: 20px;
                padding-top: 20px;
                border-top: 2px solid #DDD;
                color: $text_grey;
                strong {
                    display: block;
                    text-align: right;
                }
            }
        }
        &.property {
            background: #FFF;
            figure {
                border-bottom-color: $mid_grey;
                figcaption {
                    background: $mid_grey;
                }
            }
        }
    }
}

.nav-tabs {
    margin: 50px 0 !important;
    border-bottom: 0;
    justify-content: space-between;
    .nav-item {
        flex-basis: 24%;
        list-style-type: none;
        @media screen and (max-width: 768px) {
            flex-basis: 49%;
            margin-bottom: 10px;
        }
        @media screen and (max-width: 500px) {
            flex-basis: 100%;
            margin: 5px 0;
        }
    }
    .nav-link {
        padding: 10px 0;
        border: none;
        border-radius: 0;
        background: $light_grey;
        text-align: center;
        text-transform: uppercase;
        color: $mid_grey;
        @include font-size(10);
        &.active {
            background: $mid_grey;
            color: #FFF;
        }
    }
}

.cta {
    position: relative;
    margin: 25px 0 70px;
    padding: 20px 265px 20px 25px;
    border: 1px dashed $alt_grey;
    p {
        font-family: 'Cormorant Garamond', serif;
        font-weight: 400;
        color: $dark_blue;
        @include font-size(17);
    }
    .button {
        position: absolute;
        right: 25px;
        top: 20px;
        display: block;
        width: 220px;
        padding: 10px 25px;
        background: $dark_blue;
        color: #FFF;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;  
        transition: background .2s ease;
        &:hover {
            background: $mid_blue;
        }
    }
    @media screen and (max-width: 992px) {
        p {
            line-height: 115%;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 20px 25px;
        text-align: center;
        .button {
            position: static;
            width: 100%;
            margin-top: 20px;
        }
    }
}

.grecaptcha-badge {
    opacity: 0;
}

.carousel-indicators li {
    list-style-type: none;
}

.wow.fadeInUp {
    opacity: 0;
    animation-fill-mode: forwards;
}

.anchor-link {
    display: block;
    padding-top: 100px;
    margin-top: -100px;
}

@media screen and (max-width: 600px) {
    #wpadminbar {
        position: fixed !important;
    }
}