@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(75%);
    }
    25% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes lineHeight {
    0% {
        height: 0vh;
    }
    100% {
        height: 100vh;
    }
}

@keyframes loadingFade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes loadingContainerFade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes videoTextOne {
    0% {
        opacity: 0;
        transform: translate(-50%, -15%);
    }
    7% {
        transform: translate(-50%, -50%);
    }
    9% {
        opacity: 1;
    }
    45% {
        opacity: 1;
    }
    54% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes videoTextTwo {
    0% {
        opacity: 0;
        transform: translate(-50%, -15%);
    }
    7% {
        transform: translate(-50%, -50%);
    }
    9% {
        opacity: 1;
    }
    35% {
        opacity: 1;
    }
    44% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes bounce {
    0%   { transform: translateY(0); }
    3%  { transform: translateY(0); }
    8%  { transform: translateY(-20px); }
    13%  { transform: translateY(0); }
    15%  { transform: translateY(-5px); }
    17%  { transform: translateY(0); }
    100% { transform: translateY(0); }
}