.contact__icons {
    display: flex;
    justify-content: space-around;
    & > div {
        position: relative;
        text-align: center;
        svg {
            display: block;
            width: 70px;
            margin: 0 auto 20px;
        }
        strong {
            color: $light_blue;
            @include font-size(16);
        }
        a {
            color: $text_grey;
        }
        // &:before {
        //     content: "";
        //     position: absolute;
        //     display: block;
        //     width: 2px;
        //     height: 100%;
        //     background: #EEE;
        //     left: -50px;
        // }
        &:first-child:before {
            display: none;
        }
        @media screen and (max-width: 768px) {
            strong {
                @include font-size(14);
            }
            p {
                @include font-size(10);
            }
            &:before {
                display: none;
            }
        }
    }
    @media screen and (max-width: 600px) {
        position: relative;
        border-top: 2px solid #DDD;
        border-bottom: 2px solid #DDD;
        & > div {
            height: 100px;
            padding: 25px 0;
            flex-basis: 33.33%;
            svg {
                width: 50px;
                margin: 0 auto;
                cursor: pointer;
            }
            div {
                display: none;
                position: absolute;
                top: 102px;
                left: -175%;
                z-index: 150;
                width: 100vw;
                min-height: 155px;
                padding: 25px 0;
                background: #eee;
            }
            &:first-child {
                div {
                    left: -25%;
                }
            }
            // &:nth-child(2) {
            //     border-left: 2px solid #DDD;
            //     border-right: 2px solid #DDD;
            //     div {
            //         left: -33.33vw;
            //     }
            // }
            // &:nth-child(3) div {
            //     left: -66.66vw;
            // }
        }
    }
}

@media screen and (max-width: 600px) {
    .contact__icons--panel {
        padding-top: 0;
    }
    .contact__icons--wrapper {
        padding: 0;
    }
}

.wpcf7-form {
    margin-top: 75px;
    label {
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-family: 'Karla', sans-serif;
        font-weight: 700;
        color: $mid_grey;
        @include font-size(10);
    }
    input:not([type=submit]), select, textarea {
        width: 100%;
        padding: 10px 15px;
        margin-bottom: 25px;
        border: 1px solid $mid_grey;
        border-radius: 0 !important;
        -webkit-appearance: none;
    }
    .dropdown {
        button {
            position: relative;
            width: 100%;
            height: 41px;
            margin-bottom: 35px;
            padding: 8px 45px 15px 8px;
            border: 1px solid $mid_grey;
            border-radius: 0;
            background: #FFF;
            text-align: left;
            color: $text_grey;
            &:focus {
                outline: none;
                box-shadow: none;
            }
            &::placeholder {
                color: $mid_grey;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                height: 39px;
                width: 30px;
                top: 0;
                right: 0;
                background: $mid_grey;
            }
            &:after {
                position: absolute;
                top: 15px;
                right: 10px;
                border-top-color: #FFF;
            }
            @media screen and (max-width: 400px) {
                margin-bottom: 15px;
            }

        }
        .dropdown-menu {
            width: 100%;
            margin: 0;
            border: 1px solid $mid_grey;
            border-top: none;
            border-radius: 0;
            padding: 0;
            a {
                padding: 8px 15px;
                @include font-size(9);
            }
        }
    }
    input[type=submit] {
        display: block;
        width: 150px;
        margin: 0 auto;
        padding: 10px 25px;
        background: $dark_blue;
        text-transform: uppercase;
        color: #FFF;
        border-radius: 0 !important;
        -webkit-appearance: none;
        @media screen and (max-width: 400px) {
            width: 100%;
        }
    }
    @media screen and (max-width: 600px) {
        margin-top: 35px;
    }
}