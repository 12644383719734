/* Colours */
$text_blue:  #394B5A;
$text_grey:  #838288;

$light_blue: #4CBBE9;
$mid_blue:   #557EBB;
$dark_blue:  #2B4C7F;

$light_grey: #F5F6F7;
$alt_grey:   #D7DBDE;
$mid_grey:   #B2AFA5;