.news__results {
    .result {
        background: #FFF;
        figure {
            position: relative;
            height: 250px;
            background-size: cover;
            background-position: center center;
            border-bottom: 5px solid $mid_grey;
        }
        .result--content {
            h3, h4 {
                margin: 0;
                @include font-size(14);
            }
            h3 {
                font-weight: 700;
            }
            h4 {
                margin-bottom: 10px;
                text-transform: uppercase;
                font-family: 'Karla', sans-serif;
                color: $mid_grey;
                @include font-size(10);
            }
            .result--details {    
                p, span {
                    @include font-size(9);
                }
                span:hover {
                    color: $dark_blue;
                }
            }
        }
    }
}

.news__content {
    position: relative;
    top: -350px;
    margin-bottom: -350px;
    h3 {
        margin-bottom: 35px;
        padding-bottom: 35px;
        border-bottom: 1px solid #EEE;
        @include font-size(28);
    }
    li {
        margin-bottom: 15px;
    }
    @media screen and (max-width: 600px) {
        top: -120px;
        margin-bottom: -120px;
        h3 {
            margin-bottom: 20px;
            padding-bottom: 20px;
            @include font-size(18);
        }
    }
}

.news__hero {
    border-bottom: 5px solid $mid_grey;
    margin-bottom: 50px;
    width: 100%;
    height: 480px;
    object-fit: cover;
    @media screen and (max-width: 575px) {
        height: 220px;    
    }
    @media screen and (max-width: 400px) {
        margin-bottom: 25px;    
    }
}

.news__date {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-family: 'Karla', sans-serif;
    font-weight: 700;
    color: $mid_grey;
    @include font-size(10);
}

.news__quote {
    margin-top: 35px;
    padding-top: 35px;
    border-top: 1px solid #EEE;
    blockquote {
        position: relative;
        padding-top: 35px;
        &:before {
            content: "“";
            display: block;
            position: absolute;
            top: -45px;
            left: 0;
            color: $mid_grey;
            @include font-size(64);
        }
        &, p {
            font-family: 'Cormorant Garamond', serif;
        }
        p {
            line-height: 130%;
            color: $dark_blue;
            margin-bottom: 20px;
            @include font-size(15);
        }
        cite {
            font-style: normal;
            color: $mid_grey;
            @include font-size(12);
            span {
                display: block;
                font-family: 'Karla', sans-serif;
                font-weight: bold;
                color: $dark_blue;
                @include font-size(9);
            }
        }
    }
}

.news__agents {
    margin-bottom: 25px;
}