.case-studies__results {
    .result {
        background: #FFF;
        figure {
            position: relative;
            height: 250px;
            background-size: cover;
            background-position: center center;
            border-bottom: 5px solid $mid_grey;
        }
        .result--content {
            h3, h4 {
                margin: 0;
                @include font-size(16);
            }
            h4 {
                font-weight: 700;
            }
            .result--details {    
                p, span {
                    @include font-size(9);
                }
                span:hover {
                    color: $dark_blue;
                }
            }
        }
    }
}

.case-study__title {
    min-height: 450px !important;
    @media screen and (max-width: 1023px) {
        min-height: 360px !important;    
    }
    @media screen and (max-width: 767px) {
        min-height: 270px !important;    
    }
}

.case-study__hero {
    position: relative;
    top: -270px;
    margin-bottom: -270px;
    img {
        display: block;
        width: 100%;
        max-height: 540px;
        object-fit: cover;
        margin: 0 auto;
    }
    @media screen and (max-width: 1023px) {
        top: -150px;
        margin-bottom: -150px;
        img {
            height: 350px;
        }
    }
    @media screen and (max-width: 767px) {
        top: -100px;
        margin-bottom: -100px;
        img {
            height: 180px;
        }
    }
}

.case-study__content {
    padding-right: 80px;
    @media screen and (max-width: 768px) {
        padding-right: 0;    
    }
}

.case-study__agents {
    margin-top: 0 !important;
}

@media screen and (max-width: 600px) {
    .case-study__links span {
        display: none;
    }
}
@media screen and (max-width: 768px) {
    .case-study__agents {
        margin-top: 50px !important;    
    }
}