$vendors: -webkit-, -moz-, -ms-, -o-;

// Font size
@mixin font-size($size) {
	font-size: #{$size}px;
	font-size: #{($size / 10)}rem;
}

// General font styles
@mixin font($size, $font: $default_body_font, $style: normal, $weight: normal) {
	font-family: $font, sans-serif;
	font-weight: $weight;
	font-style: $style;
	@include font-size($size);
}

// Width and height
@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

// Positioning
@mixin position($pos, $top: auto, $right: auto, $bottom: auto, $left: auto) {
	position: $pos;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin absolute($top: auto, $right: auto, $bottom: auto, $left: auto) {
	@include position(absolute, $top, $right, $bottom, $left);
}

@mixin relative($top: auto, $right: auto, $bottom: auto, $left: auto) {
	@include position(relative, $top, $right, $bottom, $left);
}

@mixin fixed($top: auto, $right: auto, $bottom: auto, $left: auto) {
	@include position(fixed, $top, $right, $bottom, $left);
}

// Vender prefixed properties
@mixin prefix($map, $vendors: $vendors) {
	@each $prop, $value in $map {
		// For each vendor prefix
		@if $vendors {
			@each $vendor in $vendors {
				#{$vendor + $prop}: #{$value};	
			}
		}
		// Non-prefixed version
		#{$prop}: #{$value};
	}
}

// Form placeholder value styles
@mixin placeholder {
	&::-webkit-input-placeholder { @content }
	&:-ms-input-placeholder {@content}  
	&:-moz-placeholder { @content }
	&::-moz-placeholder { @content }
	&::placeholder { @content }
}

// Columns 
@mixin columns($count, $width, $gap, $rule) {
	@include prefix((
		column-count: $count,
		column-width: $width,
		column-gap: $gap,
		column-rule: $rule
	));	
}

// Set box sizing to border box
@mixin box-sizing-border-box {
	@include prefix((box-sizing: border-box));
}

// Float and clear an element
@mixin float-and-clear($float, $clear: both) {
	float: $float;
	clear: $clear;
}

// Border radius
@mixin border-radius($corners...) {
	@if length($corners) == 1 {
		@each $prefix in $vendors {
			#{$prefix}border-radius: $corners;
		}
		border-radius: $corners;
	} @else {
		@include custom-border-radius($corners...);
	}
}

@mixin custom-border-radius($top_left, $top_right, $bottom_right, $bottom_left) {
	@each $prefix in $vendors {
		// Add properties with Firefox's slightly different syntax
		@if $prefix == "-moz-" {
			@if $top_left != 0 { -moz-border-radius-topleft: $top_left; }
			@if $top_right != 0 { -moz-border-radius-topright: $top_right; }
			@if $bottom_right != 0 { -moz-border-radius-bottomright: $bottom_right; }
			@if $bottom_left != 0 { -moz-border-radius-bottomleft: $bottom_left; }
		// Add other vendors' syntax
		} @else {
			@if $top_left != 0 { #{$prefix}border-top-left-radius: $top_left; }
			@if $top_right != 0 { #{$prefix}border-top-right-radius: $top_right; }
			@if $bottom_right != 0 { #{$prefix}border-bottom-right-radius: $bottom_right; }
			@if $bottom_left != 0 { #{$prefix}border-bottom-left-radius: $bottom_left; }
		}
	}
	// Add property with no prefix
	@if $top_left != 0 { border-top-left-radius: $top_left; }
	@if $top_right != 0 { border-top-right-radius: $top_right; }
	@if $bottom_right != 0 { border-bottom-right-radius: $bottom_right; }
	@if $bottom_left != 0 { border-bottom-left-radius: $bottom_left; }
}

// Opacity
@mixin opacity($opacity) {
	opacity: $opacity;
	$ie: $opacity * 100;
	filter: alpha(opacity=$ie);
}
