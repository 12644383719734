.loading {
    /*display: none;*/
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    width: 100%;
    height: 100vh;
    background: #FFF;
    animation: loadingFade 2s forwards;
    animation-delay: 4s;
    pointer-events: none;
    .loading__container {
        animation: loadingContainerFade 1s forwards;
        animation-delay: 3s;
    }
    .line {
        position: absolute;
        display: block;
        width: 6px;
        height: 0vh;
        background: $mid_blue;
        animation: lineHeight 2.5s forwards;
        &:first-of-type {
            bottom: 0;
            left: 33.33%;
            background: linear-gradient(to bottom, $light_blue 0%, $mid_blue 50%, $dark_blue 100%); 
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4cbbe9', endColorstr='#2b4c7f', GradientType=0);
        }
        &:last-of-type {
            top: 0;
            right: 33.33%;
            background: linear-gradient(to top, $light_blue 0%, $mid_blue 50%, $dark_blue 100%); 
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2b4c7f', endColorstr='#4cbbe9', GradientType=0);
        }
    }
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 20vw;
        transform: translate(-50%, -50%);
        opacity: 0;
        animation: fadeIn 3s forwards;
    }
    @media screen and (max-width: 768px) {
        .line {
            display: none;
        }
        img {
            width: 50vw;
        }
    }
}

.home__video {
    position: relative;
    width: 100%;     
    
    .videobg {
        position: relative;
        padding: 56.25% 0 0 0;
        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: url(/images/video_mask.png) rgba(0,0,0,.3);
            z-index: 1000;
        }
    }
    
    @media screen and (min-width: 1280px) {
        
        height: 100vh;
        background: #FFF;
        
        .videobg {
            width: 100%; /* Set video container element width here */
            height: 100%; /* Set video container element height here */
            padding: 0;
            overflow: hidden;
        }

        /* horizontally center the video */
        .videobg-width {
            position: absolute;
            width: 100%; /* Change width value to cover more area*/
            height: 100%;
            left: -9999px;
            right: -9999px;
            margin: auto;
        }

        /* set video aspect ratio and vertically center */
        .videobg-aspect {
            position: absolute;
            width: 100%;
            height: 0;
            top: -9999px;
            bottom: -9999px;
            margin: auto;
            padding-bottom: 56.25%; /* 16:9 ratio */
            overflow: hidden;
        }

        .videobg-make-height {
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
        }

        .videobg-hide-controls {
            box-sizing: content-box;
            position: relative;
            height: 100%;
            width: 100%;
        }

        .videobg iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border: 0 none;
        }
    }
    
    @media screen and (min-width: 1024px) and (max-height: 768px) {
        
        height: 100vh;
        background: #FFF;
        
        .videobg {
            width: 100%; /* Set video container element width here */
            height: 100%; /* Set video container element height here */
            padding: 0;
            overflow: hidden;
        }

        /* horizontally center the video */
        .videobg-width {
            position: absolute;
            width: 100%; /* Change width value to cover more area*/
            height: 100%;
            left: -9999px;
            right: -9999px;
            margin: auto;
        }

        /* set video aspect ratio and vertically center */
        .videobg-aspect {
            position: absolute;
            width: 100%;
            height: 0;
            top: -9999px;
            bottom: -9999px;
            margin: auto;
            padding-bottom: 56.25%; /* 16:9 ratio */
            overflow: hidden;
        }

        .videobg-make-height {
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
        }

        .videobg-hide-controls {
            box-sizing: content-box;
            position: relative;
            height: 100%;
            width: 100%;
        }

        .videobg iframe {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border: 0 none;
        }
    }
    
    h1 {
        position: absolute;        
        top: 50%;
        left: 50%;
        width: 100%;
        z-index: 1100;
        opacity: 0;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #FFF;
        font-weight: 600;
        animation: videoTextOne 11s ease infinite;
        animation-delay: 4.5s;
        @include font-size(96);
        &:nth-of-type(2) {
            animation: videoTextTwo 11s ease infinite;
            animation-delay: 10s;
        }
        @media screen and (max-width: 1024px) {
            @include font-size(80);
        }
        @media screen and (max-width: 992px) {
            @include font-size(64);
        }
    }
    
    .scroll {
        display: block;
        position: absolute;
        z-index: 1100;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 80px;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        color: #FFF;
        span {
            display: block;
            transform-origin: bottom;
            animation-name: bounce;
            animation-timing-function: ease;
            animation-duration: 5s;
            animation-iteration-count: infinite;
        }
        &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            display: block;
            content: "";
            width: 2px;
            height: 50px;
            background-color: #FFF;
        }
        
    }
    
    @media screen and (max-width: 600px) {
        h1 {
            @include font-size(28);
        }
        .scroll {
            display: none;
        }    
    }
}

body.customize-support .placeholder {
    height: calc(100vh - 32px);
}

.home__blocks {
    margin-top: 70px;
    .row {
        margin-left: -25px;
        margin-right: -25px;
        & > div {
            padding-left: 25px;
            padding-right: 25px;
        }
        &:nth-child(even) > div {
            &:first-child {
                order: 2;
            }
            &:last-child {
                order: 1;
            }
        }
        @media screen and (max-width: 992px) {
            img {
                margin-bottom: 25px;
            }
            & > div {
                &:first-child {
                    order: 2 !important;
                }
                &:last-child {
                    order: 1 !important;
                }
            }
            &:last-child .arrow-button {
                margin-bottom: 50px !important;
            }
        }
    }
    @media screen and (max-width: 600px) {
        margin-top: 35px;    
    }
}

.home__latest-projects {    
    .home__latest-projects--zoom {
        position: relative;
        height: 25vw;
        max-height: 400px;
        min-height: 300px;
        margin: 50px 0 35px;
        overflow: hidden;
        &:before, &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transition: .6s ease; 
        }
        &:before {
            opacity: 0;
            z-index: 100;
            top: 0;
            right: 0;
            background: rgba(255, 255, 255, .5);
        }
        &:after {
            z-index: 110;
            height: 10px;
            background: $mid_grey;
            transition: .3s ease; 
        }
        .home__latest-projects--image {
            height: 100%;
            background-size: cover;
            background-position: center center;
            transform: scale(1.1);
            transition: .6s ease;            
        }
    }
    a:hover {
        .home__latest-projects--zoom {
            &:before {
                opacity: 1;
            }
            &:after {
                //height: 20px;
                background: $light_blue;
            }
            .home__latest-projects--image {
                transform: scale(1);
            }  
        }
        & ~ .arrow-button {
            color: $light_blue;
        }
    }
    img {
        
        border-bottom: 10px solid $mid_grey;
    }
    h4 {
        margin-bottom: 15px;
    }
}