body, p, li, form, select, input, textarea {
    font-family: 'Karla', sans-serif;
}

h1, h2, h3, h4 {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 400;
    line-height: 100%;
    font-variant-numeric: lining-nums;
    color: $text_blue;
}

h1 {
    @include font-size(46);
}

h2 {
    @include font-size(28);
}

h3 {
    margin-bottom: 15px;
    @include font-size(22);
}

h4 {
    @include font-size(18);
}

p, li {    
    margin-bottom: 1.25em;
    color: $text_grey;
    @include font-size(12);
    @media screen and (max-width: 400px) {
        @include font-size(11);
    }
}

ul, ol {
    margin: .75em 0 1.25em 20px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

li {
    list-style-type: disc;
    margin-bottom: 0;
}

p:last-of-type {
    margin-bottom: 0;
}

a {
    color: $dark_blue;
    &:hover {
        color: $mid_blue;
        text-decoration: none;
        transition: color .2s ease;
    }
}