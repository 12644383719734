.property_search__form {
    margin-top: 35px;
    text-align: left;
    label {
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 700;
        color: $mid_grey;
        @include font-size(10);
    }
    input:not([type=submit]):not([type=reset]), .dropdown button {
        width: 100%;
        height: 38px;
        //margin-bottom: 35px;
        padding: 8px 15px;
        border: 1px solid $mid_grey;
        border-radius: 0;
        background: #FFF;
        text-align: left;
        color: $text_grey;
        -webkit-appearance: none;
        @include font-size(9);
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &::placeholder {
            color: $mid_grey;
        }
        @media screen and (max-width: 400px) {
            margin-bottom: 15px;
        }
    }
    .dropdown {
        button {
            position: relative;
            padding-right: 45px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                height: 36px;
                width: 30px;
                top: 0;
                right: 0;
                background: $mid_grey;
            }
            &:after {
                position: absolute;
                top: 15px;
                right: 10px;
                border-top-color: #FFF;
            }
        }
        .dropdown-menu {
            width: 100%;
            margin: 0;
            border: 1px solid $mid_grey;
            border-top: none;
            border-radius: 0;
            padding: 0;
            max-height: 260px;
            overflow: auto;
            a {
                padding: 8px 15px;
                @include font-size(9);
            }
        }
    }
    input[type=submit] {
        width: 100%;
        height: 38px;
        border: 1px solid $dark_blue;
        border-radius: 0;
        margin-top: 34px;
        background: $dark_blue;
        color: #FFF;
        text-transform: uppercase;
        font-weight: bold;
        @include font-size(9);
        &:focus {
            outline: none;
            box-shadow: none;
        }
        @media screen and (max-width: 400px) {
            margin: 15px 0 0;    
        }
    }
    input[type=reset] {
        background: transparent;
        text-decoration: underline;
        width: 100%;
        padding: 10px 0 0;
        color: #B2AFA5;
        @include font-size(9);
        @media screen and (max-width: 400px) {
            margin-bottom: 15px;    
        }
    }
    .property_search__more {
        display: block;
        padding: 8px 15px;
        background: $mid_grey;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        color: #FFF;
        @include font-size(9);
    }
}

.property_search__controls {
    margin-bottom: 50px;
    h2 {
        font-weight: 700;
        @include font-size(24);
        @media screen and (max-width: 768px) {
            margin-bottom: 25px !important;
            @include font-size(20);
        }
    }
    ul.nav {
        margin: 0 !important;
        .nav-item {
            flex-basis: 33.33%;
            list-style: none;
            &:first-child .nav-link {
                border-left: 1px solid $mid_grey;
                &.active {
                    border-left: 1px solid $dark_blue;
                }
            }
        }
        .nav-link { 
            height: 43px;
            padding-left: 5px;
            padding-right: 5px;
            padding-top: 10px;
            border: 1px solid $mid_grey;
            border-left: none;
            text-align: center;
            color: $mid_grey;
            @include font-size(9);            
            &.active {
                background: $dark_blue;
                border: 1px solid $dark_blue;
                color: #FFF;
            }
        }
        &.property_search__controls--views .nav-item {
            flex-basis: 50%;
            .nav-link {
                position: relative;
                height: 43px;
                padding-left: 30px;
                border-radius: 0;
                svg {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    height: 16px;
                    * {
                        fill: $mid_grey;
                    }
                }
                &.active svg * {
                    fill: #FFF;
                }
            }
            &.property_search__controls--list {
                display: none;
            }
            @media screen and (max-width: 768px) {
                &.property_search__controls--map {
                    display: none;
                }
                &.property_search__controls--list {
                    display: block;
                }
            }
        }
        @media screen and (max-width: 768px) {
            margin-bottom: 10px !important;
        }
    }
}

.property_search__results {
    .result {
        figure {
            position: relative;
            height: 220px;
            background-size: cover;
            border-bottom: 5px solid $dark_blue;
            figcaption {
                position: absolute;
                bottom: -5px;
                left: 25px;
                display: block;
                background: $dark_blue;
                padding: 5px 15px;
                text-transform: uppercase;
                font-weight: bold;
                color: #FFF;
                @include font-size(9);
            }
        }
        h3 {
            margin: 0 0 5px;
            color: $mid_grey;                    
            @include font-size(16);
            @media screen and (max-width: 600px) {
                margin-top: 0 !important;
                @include font-size(13);
            }
        }
        h4 {
            margin: 0;
            font-weight: bold;
            color: $dark_blue;
            @include font-size(14);
            @media screen and (max-width: 600px) {
                @include font-size(11);
            }
        }
    }    
    &.map {
        .property_search__results--scroller {
            max-height: 867px;
            overflow: auto;
            overflow-x: hidden;
            padding-right: 15px;
            @media screen and (max-width: 768px) {
                overflow: visible;
                max-height: none;
            }
        }
        .result {            
            padding: 15px 0;
            border-top: 1px solid #DDD;
            figure {
                height: 70px;
                border-bottom: none;
            }
            h3 {
                margin-top: 8px;
            }
            .result--content-wrapper {
                position: relative; 
                img {
                    position: absolute;
                    top: -14px;
                    right: 20px;
                    cursor: pointer;
                    transition: transform .3s linear;
                }
            }
            .result--content {                
                height: 0;
                margin-top: 15px;
                padding: 0 15px;
                border-top: 10px solid #D2CCC1;
                background: #D2CCC1;
                color: #FFF;
                transition: .5s ease;
                overflow: hidden;                
                strong {
                    display: block;
                    text-align: right;
                }
                
                &.open {
                    height: 92px;
                    padding-bottom: 10px;
                    & + img {
                        transform: rotate(180deg);
                    }
                }
            }            
        }
        #map {
            height: 100%;
            min-height: 750px;
            @media screen and (max-width: 768px) {
                height: 320px;
                margin: 50px 0 0;    
            }
        }
    }
    .property_search__pagination {
        margin-top: 15px;
        text-align: center;
        ul {
            display: flex;
            justify-content: center;
            margin: 0 auto;
            li {
                display: inline-block;
                list-style: none;
                margin: 0;
                &:first-child, &:last-child {
                    a {
                        background: $mid_grey;
                        color: #FFF;
                    }
                }
            }
            a {
                display: block;
                width: 40px;
                padding: 10px 13px;
                border-top: 1px solid $mid_grey;
                border-bottom: 1px solid $mid_grey;
                font-weight: bold;
                color: $mid_grey;
                @include font-size(9);
                &.active {
                    background: $dark_blue;
                    border-top: 1px solid $dark_blue;
                    border-bottom: 1px solid $dark_blue;
                    color: #FFF;
                }
            }
        }
    }
}