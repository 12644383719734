.services__tabs {
    h2 {
        margin-bottom: 25px !important;
        @media screen and (max-width: 768px) {
            margin: 25px 0 !important;
        }
    }
    p {
        &.intro {
            font-weight: 700;
        }
    }
}