.scheme__carousel {
    figure {
        height: 500px;
        background-position: center center;
        background-size: cover;
        @media screen and (max-width: 768px) { 
            height: 400px;
        }
        @media screen and (max-width: 575px) { 
            height: 220px;
        }
    }
}

.scheme__hero  {
    text-align: center;
    img {
        width: 100%;
        object-fit: cover;
        max-height: 500px;
    }      
}

.scheme__key-points {
    display: flex;
    margin: 0 0 40px 0;
    justify-content: space-between;
    .scheme__key-points--group {
        text-align: center;
        span, strong {
            display: block;
        }
        span {
            color: $dark_blue;
        }
        strong {
            color: $light_blue;
            @include font-size(16);
        }
    }
    .scheme__key-points--separator {
        border-left: 1px solid #DDD;
        &:last-of-type {
            display: none;
        }
    }
    @media screen and (max-width: 575px) {        
        margin: 0 0 35px;
        flex-wrap: wrap;
        .scheme__key-points--group {
            flex-basis: 50%;
            padding: 10px 0;  
            &:nth-child(1) {
                border-right: 2px solid #DDD;
                border-bottom: 2px solid #DDD;
            }
            &:nth-child(3) {
                border-bottom: 2px solid #DDD;
            }
            &:nth-child(5) {
                border-right: 2px solid #DDD;
            }
        }
        .scheme__key-points--separator {
            display: none;    
        }
    }
}

.scheme__features, .scheme__map {
    h3 {
        margin-top: 40px;
        @include font-size(18);
    }
    p, li, th, td {
        @include font-size(11);
    }
}

.scheme__features {
    table {
        width: 100%;
        margin-top: 25px;
        border-top: 1px solid #DDD;
        tr {
            border-bottom: 1px solid #DDD;
        }
        th, td {
            padding: 8px 10px;
            color: $text_grey;
        }
        th {
            font-weight: normal;
        }
        td {
            font-weight: bold;
            text-align: right;
            color: $dark_blue;
        }
    }
    @media screen and (max-width: 575px) {
        p {
            @include font-size(10);
        } 
        th, td {
            @include font-size(9);
        } 
        & > div:first-child > h3 {
            margin-top: 25px;
        }
    }
}

.scheme__agents {    
    margin-top: 40px;   
    box-shadow: 0 0 10px #CCC;
    article {
        padding: 35px 35px 0;
        h4 {
            margin-bottom: 5px;
            color: #333;
            @include font-size(15);
        }
        h5, p {
            @include font-size(10);
        }
        h5 {
            margin-bottom: 15px;
            font-weight: bold;
            color: $dark_blue;
        }
        p {
            line-height: 125%;
            strong {
                display: inline-block;
                width: 20px;
            }
        } 
        &:last-of-type {
            padding-bottom: 35px;
        }
    }
    .scheme__brochure {
        display: block;
        padding: 15px 20px;
        background: $dark_blue;
        border-top: 1px solid #FFF;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        color: #FFF;
        transition: .2s ease;
        &:hover {
            background: lighten($dark_blue, 5%);
        }        
    }
    @media screen and (max-width: 575px) {
        img {
            display: none;
        } 
    }
}

.scheme__map #map {
    position: relative;
    margin: 25px 0;
    height: 450px;
    background: url(/images/map.jpg) center center no-repeat #EEE;
    background-size: cover;
    box-shadow: 0 0 10px #CCC;
    & > button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        padding: 15px 20px;
        background: $dark_blue;
        border: none;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        color: #FFF;
    }
    @media screen and (max-width: 500px) {
        height: 250px;  
        & > button {
            width: 220px;
        }
    }
}

.enquiry-form {
    display: none;
    width: 800px;
    h2 {
        text-align: center;
    }
    .wpcf7-form {
        margin-top: 25px;
        textarea {
            height: 120px;
        }
    }
}

@media screen and (max-width: 575px) {
    .scheme__links a {
        display: block;
        padding: 5px 0;
        span {
            display: none;
        }
    }    
}